import React from 'react';

const Calendar: React.FC<React.SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 9.99992C4 7.60668 5.9401 5.66659 8.33333 5.66659H31.6667C34.06 5.66659 36 7.60669 36 9.99992V33.3333C36 35.7265 34.0599 37.6666 31.6667 37.6666H8.33333C5.94011 37.6666 4 35.7265 4 33.3333V9.99992ZM8.33333 7.66659C7.04467 7.66659 6 8.71125 6 9.99992V33.3333C6 34.622 7.04466 35.6666 8.33333 35.6666H31.6667C32.9554 35.6666 34 34.622 34 33.3333V9.99992C34 8.71124 32.9554 7.66659 31.6667 7.66659H8.33333Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.6667 2.33325C27.219 2.33325 27.6667 2.78097 27.6667 3.33325V9.99992C27.6667 10.5522 27.219 10.9999 26.6667 10.9999C26.1144 10.9999 25.6667 10.5522 25.6667 9.99992V3.33325C25.6667 2.78097 26.1144 2.33325 26.6667 2.33325Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3333 2.33325C13.8856 2.33325 14.3333 2.78097 14.3333 3.33325V9.99992C14.3333 10.5522 13.8856 10.9999 13.3333 10.9999C12.781 10.9999 12.3333 10.5522 12.3333 9.99992V3.33325C12.3333 2.78097 12.781 2.33325 13.3333 2.33325Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 16.6666C4 16.1143 4.44772 15.6666 5 15.6666H35C35.5523 15.6666 36 16.1143 36 16.6666C36 17.2189 35.5523 17.6666 35 17.6666H5C4.44772 17.6666 4 17.2189 4 16.6666Z"
      />
    </svg>
  );
};

export default Calendar;
